import React, { useState, useEffect } from "react";
import TypingIndicator from "./TypingIndicator";
const ChatInputForm = ({ input, setInput, handleSubmit, botIsTyping }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          color: "black",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TypingIndicator />
        <div className="chat-input-textarea sm:ml-[20%] md:ml-[0px]   sm:w-[65%] md:w-[60%] xs:width[30%] rounded-2xl">
          <input
            rows="1"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="sm:w-[92%] md:w-[82%] mr-[100px] p-[13px] outline-none rounded-2xl"
            placeholder="Ask a question"
            disabled={botIsTyping}
          ></input>
        </div>
        <div
          className={`icon-div sm:w-[50%] md:w-[58%] xs:w-[25%] ${
            botIsTyping ? "bg-gray-200 opacity-50" : ""
          }`}
        >
          <svg
            color="black"
            onClick={handleSubmit}
            stroke="currentColor"
            fill="none"
            viewBox="0 0 23 23"
            strokeLinecap="round"
            strokeLinejoin="round"
            className=" sm:ml-[75%]"
            height="25px"
            width="5em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g mask="url(#mask0_59_292)">
              <path d="M3 20V14L11 12L3 10V4L22 12L3 20Z" fill="#5C5D72" />
            </g>
          </svg>
        </div>
      </div>
    </form>
  );
};

export default ChatInputForm;
