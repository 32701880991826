import React, { useState, useEffect } from "react";
import girl from "../images/lady.png";
import boy from "../images/boy.png";
import girl2 from "../images/lady2.png"; // Assuming girl2 image path
import boy2 from "../images/boy2.png"; // Assuming boy2 image path
import cpu from "../images/cb.png";
import cpu1 from "../images/cw.png";
const ChoreographerSelector = ({ clearChat, subject }) => {
  const [selected, setSelected] = useState(0); // To remember the selected index
  const [selectedSubjectIndex, setSelectedSubjectIndex] = useState(0);
  const buttonsData = [
    {
      id: "button1",
      image: girl2,
      altImage: girl, // Added alternate images
      name: "Dance Instructor",
      bgColor:
        selectedSubjectIndex === 0
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",

      textColor: selectedSubjectIndex === 0 ? "white" : "black",

      borderStyle: selectedSubjectIndex === 0 ? "0px" : "2px dashed #C7C5D0",
      botId: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY,
      buttonState: 1,
    },
    {
      id: "button2",
      image: girl2,
      altImage: girl, // Added alternate images
      name: "Lucy Bennett",
      bgColor:
        selectedSubjectIndex === 1
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",

      textColor: selectedSubjectIndex === 1 ? "white" : "black",

      borderStyle: selectedSubjectIndex === 1 ? "0px" : "2px dashed #C7C5D0",
      botId: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_2,
      buttonState: 2,
    },
    {
      id: "button3",
      image: boy,
      altImage: boy2, // Added alternate images
      name: "Wayne McGregor",
      bgColor:
        selectedSubjectIndex === 2
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",

      textColor: selectedSubjectIndex === 2 ? "white" : "black",

      borderStyle: selectedSubjectIndex === 2 ? "0px" : "2px dashed #C7C5D0",
      botId: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_3,
      buttonState: 3,
    },
    {
      id: "button4",
      image: boy,
      altImage: boy2, // Added alternate images
      name: "Christopher Bruce",
      bgColor:
        selectedSubjectIndex === 3
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",

      textColor: selectedSubjectIndex === 3 ? "white" : "black",

      borderStyle: selectedSubjectIndex === 3 ? "0px" : "2px dashed #C7C5D0",
      botId: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_4,
      buttonState: 4,
    },
    {
      id: "button5",
      image: boy,
      altImage: boy2, // Added alternate images
      name: "James Cousins",
      bgColor:
        selectedSubjectIndex === 4
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",

      textColor: selectedSubjectIndex === 4 ? "white" : "black",

      borderStyle: selectedSubjectIndex === 4 ? "0px" : "2px dashed #C7C5D0",
      botId: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_5,
      buttonState: 5,
    },
  ];
  const buttonsData2 = [
    {
      id: "button6",
      image: cpu,
      altImage: cpu1, // Added alternate images
      name: "CPU",
      bgColor:
        selectedSubjectIndex === 0
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",

      textColor: selectedSubjectIndex === 0 ? "white" : "black",

      borderStyle: selectedSubjectIndex === 0 ? "0px" : "2px dashed #C7C5D0",
      botId: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_6,
      buttonState: 6,
    },
    {
      id: "button7",
      image: boy,
      altImage: boy2, // Added alternate images
      name: "Alan Turing",
      bgColor:
        selectedSubjectIndex === 1
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",

      textColor: selectedSubjectIndex === 1 ? "white" : "black",

      borderStyle: selectedSubjectIndex === 1 ? "0px" : "2px dashed #C7C5D0",
      botId: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_7,
      buttonState: 7,
    },
    {
      id: "button8",
      image: boy,
      altImage: boy2, // Added alternate images
      name: "Steve Jobs",
      bgColor:
        selectedSubjectIndex === 2
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",

      textColor: selectedSubjectIndex === 2 ? "white" : "black",

      borderStyle: selectedSubjectIndex === 2 ? "0px" : "2px dashed #C7C5D0",
      botId: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_8,
      buttonState: 8,
    },
  ];
  const buttonsData3 = [
    {
      id: "button9",
      image: boy,
      altImage: boy2, // Added alternate images
      name: "Charles Darwin",
      bgColor:
        selectedSubjectIndex === 0
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",

      textColor: selectedSubjectIndex === 0 ? "white" : "black",

      borderStyle: selectedSubjectIndex === 0 ? "0px" : "2px dashed #C7C5D0",
      botId: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_9,
      buttonState: 9,
    },
  ];
  const buttonsData4 = [
    {
      id: "button10",
      image: girl2,
      altImage: girl, // Added alternate images
      name: "Elizabeth I",
      bgColor:
        selectedSubjectIndex === 0
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",

      textColor: selectedSubjectIndex === 0 ? "white" : "black",

      borderStyle: selectedSubjectIndex === 0 ? "0px" : "2px dashed #C7C5D0",
      botId: process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_10,
      buttonState: 10,
    },
  ];
  const getButtonsDataForSubject = (subject) => {
    switch (subject) {
      case "GCSE Dance":
        return buttonsData;
      case "GCSE Computer Science":
        return buttonsData2;
      case "GCSE Biology":
        return buttonsData3;
      case "GCSE History":
        return buttonsData4;
      default:
        return buttonsData;
    }
  };
  const handleClick = (buttonState, index, botId) => {
    setSelected(index);
    clearChat(buttonState, botId);
    console.log(index);
    setSelectedSubjectIndex(index);
  };
  useEffect(() => {
    setSelectedSubjectIndex(0);
    setSelected(0); // Reset selected index when subject changes
  }, [subject]);

  return (
    <div className="character flex flex-wrap justify-center m-[20px] gap-2">
      {getButtonsDataForSubject(subject).map((button, index) => (
        <button
          key={button.id}
          id={button.id}
          className="subject_button"
          type="button"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: button.bgColor,
            minWidth: "142px",
            height: "40px",
            marginLeft: "25px",
            borderRadius: "8px",
            color: button.textColor, // Changed from button.color to button.textColor
            border: button.borderStyle,
            gap: "3px",
            fontSize: "12px",
            fontFamily: "open sans",
            textAlign: "center",
          }}
          onClick={() => handleClick(button.buttonState, index, button.botId)}
        >
          <img
            src={index === selected ? button.altImage : button.image}
            alt={button.name} // Added alt for accessibility
          />
          {button.name}
        </button>
      ))}
    </div>
  );
};

export default ChoreographerSelector;
