//import logo from './logo.svg';
import "./App.css";
import "./normal.css";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Component/Login/Login";
import Home from "./Component/Home/Home";
// Calling the function for front and backend.
function App() {
  return(
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/home" element={<Home />} />
  </Routes>
  )
};

export default App;
