import { useContext } from "react";
import Background from "../images/ai.png";
import "@fontsource/open-sans"; 
function LoginPageLeftSide(props) {

  return (
    <div
      className="centered w-full h-full bg-white md:h-[100vh] p-10 md:p-30 sm:p-5 md:text-center items-center mt-0 md:mt-0 sm:pt-20 text-center justify-center"
      style={{ 
      backgroundColor:'rgba(29, 66, 164, 0.36)',
    }}

    >
      
      
      <div className="jess_icon w-full h-full left-[35px] md:left-[35px] sm:left-[35px]  text-center justify-center items-center flex ">
         <img src={Background} alt="" /> 
      </div>

  
    </div>
  );
}

export default LoginPageLeftSide;
