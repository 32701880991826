import React from 'react';

const ChatMessage = () => {
return (
<div id="headingdiv">
<h1 id="heading" className="heading1">
Chat CGP
</h1>

<h2 id="heading22" className="heading2">
<svg
stroke="currentColor"
fill="none"
strokeWidth={1.5}
viewBox="0 0 24 24"
strokeLinecap="round"
strokeLinejoin="round"
className="icon7"
height="1em"
width="1em"
xmlns="http://www.w3.org/2000/svg"
>
<circle cx={12} cy={12} r={5} />
<path d="M12 1L12 3" />
<path d="M12 21L12 23" />
<path d="M4.22 4.22L5.64 5.64" />
<path d="M18.36 18.36L19.78 19.78" />
<path d="M1 12L3 12" />
<path d="M21 12L23 12" />
<path d="M4.22 19.78L5.64 18.36" />
<path d="M18.36 5.64L19.78 4.22" />
</svg>
Examples
</h2>

<h2 id="heading3" className="heading3">
<svg
xmlns="http://www.w3.org/2000/svg"
fill="none"
viewBox="0 0 110 110"
strokeWidth={1.5}
stroke="currentColor"
aria-hidden="true"
className="icon8"
>
<path
    strokeLinecap="round"
    strokeLinejoin="round"
    d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
/>
</svg>
Capabilities
</h2>

<h2 id="heading4" className="heading4">
<svg
stroke="currentColor"
fill="none"
strokeWidth={1.5}
viewBox="0 0 24 24"
strokeLinecap="round"
strokeLinejoin="round"
className="icon9"
height="1em"
width="1em"
xmlns="http://www.w3.org/2000/svg"
>
<path d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0z" />
<path d="M12 9L12 13" />
<path d="M12 17L12.01 17" />
</svg>
Limitations
</h2>

</div>
);
};

export default ChatMessage;