import React from 'react';
const TypingIndicator = () => {
return (
<div id="typing-indicator" className="typing-indicator">
<span></span>
<span></span>
<span></span>
</div>
);
};

export default TypingIndicator;