import React from "react";
import LoginWithStudent from "./LoginWithStudent";

function LoginPageRightSide() {
  return (
    <div>
      <div className="w-full h-full bg-white centered md:h-[90vh] p- md:p-36 sm:p-5 mt-16 justify-center text-center flex">
        <div className="main-content-div w-full sm:w-[360px] md:w-full">

          <div className="w-full h-full flex justify-center items-center">
          <LoginWithStudent user_type={3} />

          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPageRightSide;