import React from "react";
import userAvatar from "../../assets/images/user.svg";
import botAvatar from "../images/avatar.png";
import botAvatar2 from "../images/avatar 2.png";
import botAvatar3 from "../images/avatar 3.png";
import botAvatar4 from "../images/avatar 4.png";
import botAvatar5 from "../images/avatar 5.png";
import botAvatar6 from "../images/avatar 6.png";
import botAvatar7 from "../images/avatar 7.png";
import botAvatar8 from "../images/avatar 8.png";
import botAvatar9 from "../images/avatar 9.png";
import botAvatar10 from "../images/avatar 10.png";
import TypeWriter from "typewriter-effect";

const ChatBox = ({ chatLog, botstate }) => {
  return (
    <div
      id="chat-log"
      className="chat-log sm:mt-[2%] sm:mb-[10%] md:mt-0 overflow-hidden md:pr-[0%] sm:-[5%] "
      style={{ display: "flex", flexDirection: "column" }}
    >
      {chatLog.map((message, index) => {
        if (message.user === "me") {
          return (
            <div className="chat-message  float-right">
              <div
                className="chat-message-center sm:pl-[10px] md:pl-[94px] lg:pl-[0px]  lg:pb-[50%] float-right"
                style={{ marginLeft: "12%" }}
              >
                <div className="message2 float-right">{message.message}</div>
                <img src={userAvatar} className="avatar_image" alt="user" />
              </div>
            </div>
          );
        } else if (message.user === "gpt") {
          return (
            <div className="chat-message">
              <div className="chat-message-center sm:pl-[10px] md:pl-[94px] lg:pl-[0px]  lg:pb-[50%]">
                <div className="avatar">
                  <img
                    src={
                      botstate === 1
                        ? botAvatar5
                        : botstate === 2
                        ? botAvatar2
                        : botstate === 3
                        ? botAvatar3
                        : botstate === 4
                        ? botAvatar4
                        : botstate === 6
                        ? botAvatar6
                        : botstate === 7
                        ? botAvatar8
                        : botstate === 8
                        ? botAvatar7
                        : botstate === 9
                        ? botAvatar9
                        : botstate === 10
                        ? botAvatar10
                        : botAvatar
                    }
                    className="avatar_image"
                    alt="bot"
                  />
                </div>

                <div className="message">
                  <TypeWriter
                    options={{
                      delay: 30,
                      cursorClassName: "type-writter-disabled-color",
                    }}
                    onInit={(typewriter) => {
                      typewriter.typeString(message.message).start();
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }
        return null; // To handle any unexpected values of message.user
      })}
    </div>
  );
};

export default ChatBox;
