import React from "react";

const SubjectSelector = ({ handleSubjectSelection, selectedSubjectIndex }) => {
  console.log("selected index", selectedSubjectIndex);
  function handleClick() {
    selectedSubjectIndex = selectedSubjectIndex + 1;
  }
  const buttonsData = [
    {
      label: "GCSE Dance",
      bgColor:
        selectedSubjectIndex === 0
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",
      onClick: (index) => handleSubjectSelection("Dance", index),
      borderStyle: "2px solid transparent",
      textColor: selectedSubjectIndex === 0 ? "white" : "black",
      width: "150px",
      borderStyle: selectedSubjectIndex === 0 ? "0px" : "2px dashed #C7C5D0",
    },
    {
      label: "GCSE Computer Science",
      bgColor:
        selectedSubjectIndex === 1
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",
      borderStyle: "2px dashed #C7C5D0",
      textColor: selectedSubjectIndex === 1 ? "white" : "black",
      borderStyle: selectedSubjectIndex === 1 ? "0px" : "2px dashed #C7C5D0",
      width: "244px",
    },
    {
      label: "GCSE Biology",
      bgColor:
        selectedSubjectIndex === 2
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",
      borderStyle: "2px dashed #C7C5D0",
      textColor: selectedSubjectIndex === 2 ? "white" : "black",
      borderStyle: selectedSubjectIndex === 2 ? "0px" : "2px dashed #C7C5D0",
      width: "171px",
    },
    {
      label: "GCSE History",
      bgColor:
        selectedSubjectIndex === 3
          ? "var(--material-theme-sys-light-primary, #5056A9)"
          : "white",
      borderStyle: "2px dashed #C7C5D0",
      textColor: selectedSubjectIndex === 3 ? "white" : "black",
      borderStyle: selectedSubjectIndex === 3 ? "0px" : "2px dashed #C7C5D0",
      width: "200px",
    },
  ];

  return (
    <div className="sub_buttons_selection flex flex-wrap justify-center gap-2">
      {buttonsData.map((btn, index) => (
        <button
          key={index}
          className="subject_button"
          type="button"
          style={{
            marginTop: "20px",
            backgroundColor: btn.bgColor,
            maxWidth: btn.width,
            height: "40px",
            borderRadius: "8px",
            padding: "5px 2%",
            whiteSpace: "nowrap",
            color: btn.textColor,
            border: btn.borderStyle,
            fontSize: "14px",
            fontFamily: "open sans",
          }}
          onClick={() => handleSubjectSelection(btn.label, index)}
        >
          {btn.label}
        </button>
      ))}
    </div>
  );
};

export default SubjectSelector;
