import axios from "axios";
import React, { useEffect, useState } from "react";
import Chatedu from "../images/b1.png";
import SubjectSelector from "./SubjectSelector";
import ChoreographerSelector from "./ChoreographerSelector";
import ChatMessage from "./ChatMessage";
import ChatBox from "./ChatBox";
import ChatInputForm from "../Home/ChatInputForm";

function Home() {
  const [input, setInput] = useState("");
  // Setting a pre draft chat log
  const [chatLog, setChatLog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [botstate, setBotState] = useState([]);
  const [botIsTyping, setBotIsTyping] = useState(false);
  const [subject, setSubject] = useState("GCSE Dance");
  const [selectedSubjectIndex, setSelectedSubjectIndex] = useState(0);

  const handleSubjectSelection = (selectedSubject, index) => {
    setSubject(selectedSubject);
    setSelectedSubjectIndex(index);
    if (index == 0) {
      clearChat(1, process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY);
    } else if (index == 1) {
      clearChat(6, process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_6);
    } else if (index == 2) {
      clearChat(9, process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_9);
    } else if (index == 3) {
      clearChat(10, process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_10);
    }
  };
  function clearChat(buttonState, botId) {
    setChatLog([]);
    setBotState(buttonState);
    console.log(buttonState);
    console.log(botId);
    getGreetingMessage(botId);
  }

  function showTypingIndicator() {
    let typingIndicator = document.getElementById("typing-indicator");
    typingIndicator.style.display = "block";
    setBotIsTyping(true);
  }

  function hideTypingIndicator() {
    let typingIndicator = document.getElementById("typing-indicator");
    typingIndicator.style.display = "none";
  }

  // Function to fetch input and get reply from server
  async function handleSubmit(e) {
    let botId;
    if (botstate === 1) {
      botId = process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY;
    } else if (botstate === 2) {
      botId = process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_2;
    } else if (botstate === 3) {
      botId = process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_3;
    } else if (botstate === 4) {
      botId = process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_4;
    } else if (botstate === 6) {
      botId = process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_6;
    } else if (botstate === 7) {
      botId = process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_7;
    } else if (botstate === 8) {
      botId = process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_8;
    } else if (botstate === 9) {
      botId = process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_9;
    } else if (botstate === 10) {
      botId = process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_10;
    } else {
      botId = process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY_5;
    }
    console.log("here bot stat", botstate);

    if (input === "") {
      alert("Please type some message");
      return;
    }

    // clearChat(botstate);
    e.preventDefault();
    setLoading(true);
    showTypingIndicator();
    // setTimeout(() => {
    //   showTypingIndicator();
    // }, 1000);

    // Taking input from the user
    let chatLogNew = [...chatLog, { user: "me", message: `${input}` }];
    setInput("");
    setChatLog(chatLogNew);
    axios
      .request({
        method: "POST",
        body: input,
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          bot_id: botId,
          msg: input,
          session_name: "",
          forwardMessageFlag: "false",
        }),
      })
      .then((res) => {
        console.log(res.data.response);
        setChatLog([
          ...chatLogNew,
          { user: "gpt", message: `${res.data.response}` },
        ]);

        setLoading(false);
        hideTypingIndicator();
        setBotIsTyping(false);
      });
  }

  // Side menu of our chatgpt it's interface
  useEffect(() => {
    setBotState(1);
    document.getElementById("headingdiv").style.display = "none";
    getGreetingMessage(process.env.REACT_APP_REST_WEB_WIDGET_ACCESS_KEY);
  }, []);

  const getGreetingMessage = (botId) => {
    // Hide the inputs element as soon as the function starts (i.e., the request begins)
    /* document.getElementById("inputs").style.display = "none"; */
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_welcome_messages",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          bot_id: botId,
        }),
      })
      .then((res) => {
        console.log(res.data.user_messages);
        var greetingsStringArray = res.data.user_messages;
        let concatedGreetingsArray = greetingsStringArray.join("<br/>");
        console.log("CONCATED: ", concatedGreetingsArray);

        setChatLog([{ user: "gpt", message: `${concatedGreetingsArray}` }]);
        // Show the inputs element once the response is received
      })
      .catch((error) => {
        console.error("Error fetching greeting message:", error);

        // Even in the case of an error, show the inputs element
      });
    /* if (setChatLog) {
      document.getElementById("inputs").style.display = "block";
    }*/
  };

  useEffect(() => {
    var objDiv = document.getElementById("chatbox");
    objDiv.scrollTop = objDiv.scrollHeight;
    var objDiv1 = document.getElementById("chat-log");
    objDiv1.scrollTop = objDiv1.scrollHeight;
  }, [chatLog]);
  return (
    <>
      <div className="App">
        <div
          className="left  w-[70%] m-4 rounded-lg self  overflow-hidden"
          style={{ border: "1px solid black" }}
        >
          <SubjectSelector
            clearChat={clearChat}
            setSubject={setSubject}
            handleSubjectSelection={handleSubjectSelection}
            selectedSubjectIndex={selectedSubjectIndex}
          />
          <ChoreographerSelector clearChat={clearChat} subject={subject} />
          <section className="bg chatbox mt-[3%] self-center" id="chatbox">
            <ChatMessage />
            <ChatBox chatLog={chatLog} botstate={botstate} />
            <div
              id="inputs"
              className="chat-input-holder sm:left-0 "
              style={{ width: "91%", marginLeft: "-10%" }}
            >
              <ChatInputForm
                input={input}
                setInput={setInput}
                handleSubmit={handleSubmit}
                botIsTyping={botIsTyping}
              />
              <p className="belowtext" style={{ marginTop: "30px" }}>
                {/* ChatCGP Jan 30 Version. Free Research Preview. Our goal is to make
          AI systems more natural and safe to interact with. Your feedback
          will help us improve. */}
              </p>
            </div>
          </section>
        </div>
        <div className="right w-[30%] self-center">
          <img src={Chatedu}></img>
        </div>
      </div>
    </>
  );
}

export default Home;
